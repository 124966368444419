import axios from 'axios'
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';

import { PageSection } from '../../components/page';
import Layout from '../../components/layouts/en';
import { PostContainer, Content, Excerpt } from './{StrapiBrookfieldMedias.slug}'


const Media = ({ location }) => {
  const [data, setData] = useState()
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const slug = params.get('slug')

    axios
      .get(`https://cms.officelist.pl/brookfield-medias?slug=${slug}&_publicationState=preview`)
      .then(resp => {

        if (resp.status === 200 && resp.data.length > 0) {
          setData(resp.data[0]);
        }
      })
  }, [])

  return <Layout>
    <PageSection>
      <PostContainer>
        <Row>
          {data && <Col>
            <h1>{data.title}</h1>
            <Excerpt>{data.excerpt}</Excerpt>
            <Content dangerouslySetInnerHTML={{__html: data.content}} />
          </Col>}
        </Row>
      </PostContainer>
    </PageSection>
  </Layout>
};

export default Media